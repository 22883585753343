import React from "react"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import PropTypes from "prop-types"; 

export default function Popup({show=true, handleClose, title, desc, callToAction, callback, id}) {

  return (
    <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {desc}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() =>{callback(id)}}>{callToAction}</Button>
        </Modal.Footer>
      </Modal>
  );
}

Popup.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  desc: PropTypes.string,
  callToAction: PropTypes.string,
  callback: PropTypes.func,
  id: PropTypes.string,
}
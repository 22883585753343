import React, {useEffect} from 'react';
import { useExternalApiAuthMutation } from '../app/api';

/*import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/esm/Button';*/

export default function SquareAccept() {

  const queryParameters = new URLSearchParams(window.location.search);
  console.log('my query params', queryParameters);

  const code = queryParameters.get("code");
  const state = queryParameters.get("state");
  const [addAuth] = useExternalApiAuthMutation();

  useEffect(() => {
    addAuth({code: code, state: state, type: 'SQUARE_AUTH'});
  }, []);

  return (
    <p>Thank you!</p>
  )
}

import React from "react";
import PropTypes from "prop-types";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function LoginError({ errorStatusCode }) {
  return (
    <Row className='justify-content-sm-center flex-fill gy-5 mb-2'>
      <Col className='text-center w-100 grey'>
        {errorStatusCode === 401
          ? "Username or password is incorrect"
          : errorStatusCode === 123
          ? "Passwords do not match"
          : errorStatusCode === 222 
          ? "Permissions Pending"
          : errorStatusCode === 111
          ? "User Already Exists"
          : "Temporarily unable to login"}
      </Col>
    </Row>
  );
}

LoginError.propTypes = {
  // The error status code from the last login attempt
  errorStatusCode: PropTypes.number,
};

export default LoginError;

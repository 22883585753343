import React, {useEffect} from 'react';
import { useExternalApiAuthMutation } from '../app/api';
import {useCreateApiAuthMutation} from "../app/api";

/*import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/esm/Button';*/

export default function GoogleAccept() {

  const queryParameters = new URLSearchParams(window.location.search);
  console.log('my query params', queryParameters);

  const code = queryParameters.get("code");
  const state = queryParameters.get("state");
  const refresh_token = queryParameters.get("refresh_token");
  const [addAuth] = useExternalApiAuthMutation();
  const [addApiValue] = useCreateApiAuthMutation();

  useEffect(() => {
    if (refresh_token) {
      console.log('WHOA');
    }
    else {
      tryForToken(code);
      console.log('state', state);
    }
  }, []);

  const tryForToken = (code) => {
    var http = new XMLHttpRequest();
    var url = 'https://oauth2.googleapis.com/token';
    var params = 'client_id=733667762216-t88q1ug1njlm7rgsdpb2nsbql7kug1td.apps.googleusercontent.com&client_secret=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&grant_type=authorization_code&code='+ code +'&redirect_uri=https%3A//foodtruck-hub.com/google_auth';
    http.open('POST', url, true);

    http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

    http.onreadystatechange = function() {//Call a function when the state changes.
      console.log('Logging result:', http.responseText);
      if(http.readyState == 4 && http.status == 200) {
        const resultJson = JSON.parse(http.responseText);
        const access_token = resultJson.access_token;
        const refresh_token = resultJson.refresh_token ;
        saveTokenDetails(access_token, state, refresh_token, addAuth, addApiValue, 'first');
      }
    }
    http.send(params);
  }

  return (
    <p>Thank you!</p>
  )
}


export const refreshAccessToken = async (refreshToken, state, addAuth, addApiValue, callback) => {
    var http = new XMLHttpRequest();
    var url = 'https://oauth2.googleapis.com/token';
    var params = 'client_id=733667762216-t88q1ug1njlm7rgsdpb2nsbql7kug1td.apps.googleusercontent.com&client_secret=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&grant_type=refresh_token&refresh_token=' + refreshToken;
    http.open('POST', url, true);

    http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

    http.onreadystatechange = function() {//Call a function when the state changes.
      console.log('Logging result for refresh token:', http.responseText);
      if(http.readyState == 4 && http.status == 200) {
        const resultJson = JSON.parse(http.responseText);
        const access_token = resultJson.access_token;
        const refresh_token = resultJson.refresh_token ;
        saveTokenDetails(access_token, state, refresh_token, addAuth, addApiValue, 'refresh');
        console.log('returning :::', access_token);
        callback(access_token);
      }
    }
    http.send(params);
    console.log('refreshAccessToken triggered');
  }

export  const saveTokenDetails = async (access_token, state, refresh_token, addAuth, addApiValue, saveType) => {
  console.log('looking for GOOGLE_AUTH for ', state);

  let updateType = 'GOOGLE_AUTH'; // first time
  if (saveType == 'refresh') {
    updateType = 'GOOGLE_CAL';
  }
      const username =  await addAuth({code: access_token, state: state, type: updateType});
      console.log('username:', username, 'refresh_token', refresh_token);
      if (refresh_token) {
        addApiValue({username: username.data, api_type: 'GOOGLE_REFRESH_AUTH', api_value: refresh_token, unique_id: state})
      }
  }

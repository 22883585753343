import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../app/api";

const initialState = {
  // The access token used to authenticate requests to the API
  accessToken: window.localStorage.getItem("token") || undefined,

  // The authenticated username
  username: window.localStorage.getItem("username") || undefined,

  // The authenticated user_id
  user_id: window.localStorage.getItem("user_id") || undefined,
};

export const selectAccessToken = (state) => state.auth.accessToken;
export const selectUserName = (state) => state.auth.username;
export const selectUserId = (state) => state.auth.user_id;

// Save the access token to local storage
export function saveAccessToken(accessToken) {
  window.localStorage.setItem("token", accessToken);
}

// Save the access token and username to local storage
export function saveCredentials(accessToken, username, user_id) {
  saveAccessToken(accessToken);
  window.localStorage.setItem("username", username);
  window.localStorage.setItem("user_id", user_id);
}

// Remove the access token and username from local storage
function removeCredentials() {
  window.localStorage.removeItem("token");
  window.localStorage.removeItem("username");
  window.localStorage.removeItem("user_id");
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      // This private reducer is not exported. The logout function below is the
      // public interface to log out, which also removes credentials from local
      // storage.
      state.accessToken = undefined;
      state.username = undefined;
      state.user_id = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.login.matchFulfilled, (state, action) => {
      // Get the authentication from a successful login response from the API
      if (action.payload.permissions[0] != 'PENDING') {
        console.log("payload", action.payload);
        state.accessToken = action.payload.access_token;
        state.username = action.meta.arg.originalArgs.username;
        state.user_id = action.payload.user_id;
      }
    });
  },
});

// Log out of the application.
//
// Call this instead of dispatching the logout() action directly, since this
// function also removes the token from the browser local storage.
export function logout() {
  return function logoutThunk(dispatch) {
    removeCredentials();
    dispatch(authSlice.actions.logout());
  };
}

export default authSlice.reducer;

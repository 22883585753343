import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import Root from "./routes/Root";
import Error from "./routes/Error";
import Resolve from "./routes/Resolve";
import Home from "./routes/Home";
import Dashboard from "./routes/Dashboard";
import Purchase from "./routes/Purchase";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AuthRequired from "./features/auth/AuthRequired";
import LoginPage from "./routes/LoginPage";
import 'bootstrap/dist/css/bootstrap.css';
import Privacy from "./routes/Privacy";
import Terms from "./routes/Terms";
import SquareAccept from "./routes/SquareAccept";
import GoogleAccept from "./routes/GoogleAccept";


// Front-end routes
const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Resolve />,
      },
      {
        path: "home",
        element: <Home />,

      },
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "privacy",
        element: <Privacy />,
      },
      {
        path: "terms",
        element: <Terms />,
      },
      {
        path: "square_auth",
        element: <SquareAccept />,
      },
      {
        path: "google_auth",
        element: <GoogleAccept />,
      },
      {
        path: "google_token",
        element: <GoogleAccept />,
      },
      {
        element: <AuthRequired />,
        children: [
          {
            path: "dashboard",
            element: <Dashboard />,
          },
          {
            path: "purchase",
            element: <Purchase />,
          },
        ],
      },
    ],
  },
]);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, {useState} from "react"
import { useRef } from "react";
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus, faSquareMinus } from "@fortawesome/free-regular-svg-icons";

import PropTypes from "prop-types"; 

export default function OgoMenu({ogoId, callback}) {


  const [menuItems, setMenuItems] = useState([]);
  const menuItemRef = useRef();
  const menuQuantityRef = useRef();

  const addMenuItem = () => {
    let m = {}
    m.item = menuItemRef.current.value;
    m.quantity = menuQuantityRef.current.value;
    let newArray = [...menuItems];
    newArray.push(m);
    setMenuItems(newArray);

    //clear
    menuItemRef.current.value=null;
    menuQuantityRef.current.value=null;

  }

  const removeMenuItem = (item) => {
    let withoutItem = menuItems.filter((t) => t.item !== item);
    setMenuItems(withoutItem); 
  }

  const currentMenu = () => {
    return menuItems.map((m,i) => (
      <Row key={i} className='mt-1'>
        <Col xs={11}>
          {m.quantity} {m.item}
        </Col>
        <Col xs={1}>
          <FontAwesomeIcon className='add-menu remove' icon={faSquareMinus} onClick={()=>{removeMenuItem(m.item)}}/> 
        </Col>
      </Row>
    ))
  }

  return (
    <>
      <div className='divider-bar text-center'>
        Menu
      </div>
      <div className='existing-menu mt-4'> {menuItems.length > 0 ? currentMenu() : <div className='w-100 text-center'>No Items Selected<br/> Please select items and quantities below</div> }</div>
      <div className='divider-bar text-center mb-4 mt-4'>
        Add Items
      </div>
      <Container>
        <form>
          <Row className='existing-menu mt-4'>
            <Col xs={8}  >
              <input ref={menuItemRef} type="text" className="form-control" placeholder="Enter Menu Item"></input>
            </Col>
            <Col xs={3}  >
              <input ref={menuQuantityRef} type="text" className="form-control" placeholder="#"></input>
            </Col>
            <Col xs={1}>
              <FontAwesomeIcon className='add-menu' icon={faSquarePlus} onClick={()=>{addMenuItem()}}/> 
            </Col>
          </Row>
          <Button variant='info w-100 mt-4' onClick={()=>{callback(ogoId, JSON.stringify(menuItems))}}>Save Menu</Button>
        </form>
      </Container>
    </>
  );
}

OgoMenu.propTypes = {
  ogoId: PropTypes.string,
  callback: PropTypes.func, 
}


import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";

import {useGetUserApisQuery, useCreateApiAuthMutation} from "../app/api";
import { useExternalApiAuthMutation } from '../app/api';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
//import Button from 'react-bootstrap/esm/Button';
//import dompurify from 'dompurify'; 
import { createUniqueID } from './Square';

import { refreshAccessToken } from './GoogleAccept';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-regular-svg-icons';
import { faBookmark as faSolidBookmark, faSquareArrowUpRight} from '@fortawesome/free-solid-svg-icons';
import Popup from './UIElements/Popup';

import OgoEvent, {isOgoEvent} from './UIElements/OgoEvent';

export default function Calendar({calendarInUse, callbackToActivate, username}) {


  const {data: apiData} = useGetUserApisQuery({username: username});
  const [addAuth] = useExternalApiAuthMutation();
  const [addApiValue] = useCreateApiAuthMutation();

  /** get User Specific API settings **/
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [thisState, setThisState] = useState(null);

  const [showEvent, setShowEvent] = useState(null);
  const [showDesc, setShowDesc] = useState(null);

  const [showAddPopup, setShowAddPopup] = useState(null);
  const [showRemovePopup, setShowRemovePopup] = useState(null);

  const [maxTries, setMaxTries] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (apiData) {
      let googleType = apiData.filter(t => t.api_type == 'GOOGLE_CAL')?.sort((a,b) => a-b).reverse(); 
      if (googleType) {
        setAccessToken(googleType[0]?.api_value);
      }
      let googleRefreshType = apiData.filter(t => t.api_type == 'GOOGLE_REFRESH_AUTH')?.sort((a,b) => a-b).reverse(); 
      if (googleRefreshType) {
        setRefreshToken(googleRefreshType[0]?.api_value);
        setThisState(googleRefreshType[0]?.unique_id)
      }
    }
  }, [apiData]);

  useEffect(() => {
    if (accessToken) {
      listUpcomingEvents();
    }
  }, [accessToken]);


  useEffect(() => {
    if (showEvent) {
      console.log('DESCRIPTION IS:', showEvent.description)
    }
  }, [showEvent])

  /* Create Google Auth redirect URL */
  const stateUnique = createUniqueID(10);
  const redirectURL = 'https%3A//foodtruck-hub.com/google_auth'
  const clientId = "733667762216-t88q1ug1njlm7rgsdpb2nsbql7kug1td.apps.googleusercontent.com";
  const googleURL = 'https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A//www.googleapis.com/auth/calendar&access_type=offline&include_granted_scope=true&response_type=code&state=' + stateUnique + '&redirect_uri='+ redirectURL + '&client_id=' +clientId; 
  const authenticateGoogle = () => {
    addApiValue({username: username, api_type: 'GOOGLE_AUTH', api_value: 'temp', unique_id: stateUnique})
    window.open(googleURL, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
  }


  const [events, setEvents] = useState(null);

  const prepEvents = (newToken) => {
    console.log('setting prep events', newToken);
    setLoading(false);
    setAccessToken(newToken);
  }

  const listUpcomingEvents = async () => {
   
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = async function () {
      console.log('in xhr', this);
      if (this.readyState == 4 && this.status == 200) {
        let eventInfo = JSON.parse(this.response);
        setEvents(eventInfo.items);
        callbackToActivate(true);
        setLoading(false);
        console.log('events', events);
      }
      else {
        if (this.readyState == 4 && this.status != 200) {
          if (maxTries <= 3) {
            console.log('token error:', this);
            console.log('refresh_token?', refreshToken);
            setMaxTries(maxTries + 1);
            // Did token expire?
            if (refreshToken) {
              // Grab a new access token
              console.log('requesting new Access Token with refresh');
              refreshAccessToken(refreshToken, thisState,  addAuth, addApiValue, prepEvents); // send with a callback
            }
            else {
              setAccessToken(null);
            }
          }
        }
      }
    };
    let nowTime = (new Date).toISOString();
    console.log('nowTime', nowTime);
    xhr.open('GET', 'https://www.googleapis.com/calendar/v3/calendars/primary/events?maxResults=20&orderBy=startTime&singleEvents=true&timeMin=' + nowTime);
    xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
    xhr.send();
  }

  

  const isEventToday = (t) => {
    const today = new Date();
    const eventDate = new Date(t.start.dateTime);
    let daysUntil = '';
    let stop = false;

    if (today.getDate() == eventDate.getDate() && today.getMonth() == eventDate.getMonth() && today.getFullYear() == eventDate.getFullYear()) {
      daysUntil = 'TODAY!!!';
      stop = true;
    }
    if (!stop && today.getMonth() == eventDate.getMonth() && today.getFullYear() == eventDate.getFullYear()) {
      daysUntil = eventDate.getDate() - today.getDate() + ' days Until..';
      stop = true;
    }
    if (!stop && today.getFullYear() == eventDate.getFullYear()) {
      daysUntil = 'Not this month' 
      stop = true;
    }
    if (!stop ) {
      daysUntil = 'Not this year' 
      stop = true;
    }
    //return evenColate.getTime() - today.getTime();
    return (daysUntil);
  }

  const showAddPopUpModal = (id) => {
    setShowAddPopup(id);
  }

  const closeAddPopup = () => {
    setShowAddPopup(null);
  }

  const showRemovePopUpModal = (id) => {
    setShowRemovePopup(id);
  }

  const closeRemovePopup = () => {
    setShowRemovePopup(null);
  }


 const updateShowEvent = (newEvent) => {
  if (newEvent) {
    setShowEvent(newEvent);
    setShowDesc(newEvent.description) 
  }
  else {
    setShowEvent(null);
    setShowDesc(null) 
  }
 }
 
 const addOgoEvent = (id) => {
    var xhr = new XMLHttpRequest();
    var event =  events.find(t => t.id == id);
    console.log('event is ', event);
    event.description = event.description + ' #ogoevent';
    console.log('new event?', event);
    if(showEvent){updateShowEvent(event)};

    xhr.open('PATCH', 'https://www.googleapis.com/calendar/v3/calendars/' + 'primary' + '/events/' + id);
    xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.send(JSON.stringify(event));
    setShowAddPopup(null);
  }

  const removeOgoEvent = (id) => {
    var xhr = new XMLHttpRequest();
    let event = events.find(t => t.id == id);
    event.description = event.description.replaceAll('#ogoevent','') ;
    console.log('new event?', event);
    if(showEvent) {updateShowEvent(event)};

    xhr.open('PATCH', 'https://www.googleapis.com/calendar/v3/calendars/' + 'primary' + '/events/' + id);
    xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.send(JSON.stringify(event));
    setShowRemovePopup(null);
  }

  const updateMenu = (id, menu) => {
    console.log('updating menu to', menu);
    var xhr = new XMLHttpRequest();
    var event =  events.find(t => t.id == id);
    console.log('event is ', event);
    event.description = event.description + ' #ogomenu ' + menu + ' /#ogomenu';
    console.log('new event?', event);
    if(showEvent){updateShowEvent(event)};

    xhr.open('PATCH', 'https://www.googleapis.com/calendar/v3/calendars/' + 'primary' + '/events/' + id);
    xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.send(JSON.stringify(event));
    setShowAddPopup(null);
  }

  const closeEvent = () => 
    { updateShowEvent(null);}

  const setChosenEvent = (id) => {
    const chosenEvent = events.find(e=>e.id == id);
    updateShowEvent(chosenEvent);
  }
  console.log("show event is", showEvent);

  const eventList = () => {
    return events.map((t,i) => (
      <Row key={t.id} className={isOgoEvent(t) ? 'ogoevent events' : 'events'}> 
        {i != 0 && <hr/>}
        <Row className='mb-2' >
          <Col id={t.id} className='col-10' >
            <Row>
            <div className='leftside'>{isEventToday(t)}</div>
            </Row>
            <Row>
              {isOgoEvent(t) && <div className='hub-orange'>OGO EVENT</div>}
            </Row>
            <Row>
              <Col >
                {showAddPopup && showAddPopup == t.id &&  <Popup handleClose={closeAddPopup} title={'Set OGO Event'} desc={'Set event ' + t.summary + ' as OGO Event?'} callToAction={'Mark OGO Event'} callback={addOgoEvent} id={t.id} />}
                {showRemovePopup && showRemovePopup == t.id &&  <Popup handleClose={closeRemovePopup} title={'Unflag OGO Event'} desc={'Unflag event ' + t.summary + ' as OGO Event?'} callToAction={'Remove OGO Event'} callback={removeOgoEvent} id={t.id} />}
                {showEvent && showEvent.id == t.id && <OgoEvent handleClose={closeEvent} ogoEvent={showEvent} description={showDesc} addOgoEvent={addOgoEvent} removeOgoEvent={removeOgoEvent} menuCallback={updateMenu}/>}
                {!isOgoEvent(t) && <FontAwesomeIcon icon={faBookmark} onClick={()=>{showAddPopUpModal(t.id)}} /> }
                {isOgoEvent(t) && <FontAwesomeIcon icon={faSolidBookmark} className='purple' onClick={()=>{showRemovePopUpModal(t.id)}} /> }
                <b>&nbsp;&nbsp;{new Date(t.start.dateTime).toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'})} {t.summary} </b>
              </Col>
            </Row>
          </Col>
          <Col className='col-2'>
            <div className='rightside'>
                <div className='mr-4'>{new Date(t.start.dateTime).toLocaleDateString()}</div>
                <FontAwesomeIcon className='inline rightside' onClick={()=> {setChosenEvent(t.id)}} icon={faSquareArrowUpRight}/>
            </div>
          </Col>
        </Row>
      </Row>
    ))
  }


  const eventView = ()=> {
    if (!events) {
      <p>No Events to show</p>
    }
    else {
        if (loading) {
          return (<p>Loading...</p>)
        }
        else {
          return (
            <>
              <Container className='text-left p-2'>
                {eventList()}
              </Container>
            </>
          )
        }
      }
  } 

  const ogoCount = () => {
    let oCount = 0;
    events.map(e => {
      if (isOgoEvent(e)) {
        oCount++;
      }
    })
    return oCount;
  }

  return (
    <>
      {calendarInUse && events &&  
            <div className='divider-bar text-center'>
              <p className='inline '><b>{ogoCount()} OGO Events</b></p> 
            </div>
      }
      <Container  id = 'auth' className=''>
        <Row>
          <Col className={calendarInUse ? 'window text-center in-use' : 'window text-center'}>
            <div id = 'calendar'>
              {!accessToken && <p onClick={()=>{authenticateGoogle()}} className='engraved add-me'>Add<br/> Calendar</p>}
              {eventView()}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

Calendar.propTypes = {
  calendarInUse: PropTypes.bool,
  callbackToActivate: PropTypes.func,
  username: PropTypes.string,
}

import React from "react";
import { useSelector } from "react-redux";
import { selectUserName } from "../features/auth/authSlice";
import { Navigate } from "react-router-dom";

export default function Resolve() {
  // If logged in, go to the home page
  if (useSelector(selectUserName)) {
    return <Navigate replace to="dashboard" />;
  }
 
  //otherwise:
  return <Navigate replace to="home" />;
}

import React from "react";
import { useSelector } from "react-redux";
import { selectUserName } from "../features/auth/authSlice";
import Login from "../features/auth/Login";
import { Navigate } from "react-router-dom";

export default function LoginPage() {
  // If already logged in, redirect to the home page
  if (useSelector(selectUserName)) {
    return <Navigate replace to="/dashboard" />;
  }

  // Render the login form
  return <Login />;
}

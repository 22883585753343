import React, {useEffect, useState} from "react";

export default function Header() {
  const [currentDate, setCurrentDate] = useState('');
  const [currentTime, setCurrentTime] = useState('');

  console.log('date', currentDate);
  console.log('time', currentTime);

  useEffect(() => {
    getTodaysDate();
    setInterval(getTodaysDate, 60000);
  }, []);
  
  const getTodaysDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(1, '0');
    const mm = String(today.getMonth() + 0).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    let todayDate = mm + '/' + dd + '/' + yyyy;
    console.log('todayDate', todayDate);
    setCurrentDate(today.toLocaleDateString('us', {weekday: 'long'}) + ' ' + todayDate );
    setCurrentTime(today.toLocaleTimeString());
  }

  return (
    <div className="header-bar">
      <p className='leftside'><b>{currentDate}</b></p>
      <p className='rightside'><b>{currentTime}&nbsp;&nbsp;&nbsp;</b></p>
    </div>
  );
}

import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserId } from "../features/auth/authSlice";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Header from "./Header";

import dropin from "braintree-web-drop-in";

import { useGetConfigQuery } from "../app/api";
import { useRunCreatePaymentMutation, useGetBTClientTokenMutation } from "../app/api";

import Hub from '../styles/images/ftc-hub.png';

export default function Purchase() {


  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(null);

  const navigate = useNavigate();
  const [buy, setBuy] = useState(false);

  const {data: configs} = useGetConfigQuery();
  const [runCreatePayment] = useRunCreatePaymentMutation();

  console.log('getConfig is ', configs); // pass this for authorization instead of client token if you do not want to default to customer's saved payment methods

  const [braintreeInstance, setBraintreeInstance] = useState(undefined);

  const userId = useSelector(selectUserId);
  console.log('userId is', userId);
  const [getClientToken ]= useGetBTClientTokenMutation();
  const [btClientToken, setBtClientToken] = useState(null);
  const [btCustId, setBtCustId] = useState(null);

  const waitForToken = async () => {
      const newBtClientToken = await getClientToken({user_id: userId});
      console.log("look what I got", newBtClientToken.data);
      setBtClientToken(newBtClientToken.data.client_token);
      setBtCustId(newBtClientToken.data.bt_cust_id);
  }

  useEffect(() => {
    setError(null)
    setSuccess(null)
  }, [buy])

  useEffect(() => {
    if (userId) {
      waitForToken();
    }
  }, [userId])

  useEffect(() => {
          console.log('setting dropin for token', btClientToken);
        if (btClientToken) {
          console.log('setting dropin for token', btClientToken);
            const initializeBraintree = () => dropin.create({
                // insert your tokenization key or client token here
                authorization: btClientToken , 
                container: '#braintree-drop-in-div',
                dataCollector: true,
            }, function (error, instance) {
                if (error)
                    console.error(error)
                else
                    setBraintreeInstance(instance);
            });

            if (braintreeInstance) {
                setBuy(false);
                braintreeInstance
                    .teardown()
                    .then(() => {
                        initializeBraintree();
                    });
            } else {
                initializeBraintree();
            }
        }
    }, [btClientToken])


  const runPaymentNow = async (payload) => {
    setLoading('Loading...');
    const payResult = await runCreatePayment({nonce: payload.nonce, device_data: payload.deviceData , user_id: userId, bt_cust_id: btCustId});
    onPaymentCompleted(payResult.data);
  }

  const onPaymentCompleted = (response) => {
    if (response == 'SUCCESS') {
      // do successful things
      setLoading(null)
      setSuccess(true)
    }
    else {
      setError(response)      
      setLoading(null)
    }
  }

  const bt = 
    <div className = {buy ? "" : "hidden"}>
      {success && 
        <>
          <div className='alert alert-success'>
            Your Purchase was Successful 
          </div>
          <p> Welcome to OGO! </p>
        </>
      }
      {error && 
        <div className='alert alert-danger'>
          {error} 
        </div>
      }
      <div className={success ? 'hidden':''}>
        <div id={"braintree-drop-in-div"} />
        {!loading && <Button
          className={"braintreePayButton w-100"}
          type="primary"
          disabled={!braintreeInstance}
          size = 'lg'
          onClick={() => {
              if (braintreeInstance) {
                  braintreeInstance.requestPaymentMethod(
                      (error, payload) => {
                          if (error) {
                              console.error(error);
                          } else {
                            runPaymentNow(payload);
                          }
                      });
              }
          }}
        >{"Pay"}</Button>
        } 
      </div>
      {loading && <p>Processing... Please wait...</p>}
  </div>

  const mainDash =  
    <>
      <Row className='text-center mt-4 mb-4'>
        <Col className='md-1'/>
        <Col className='md-1'>
          <img className='hub-image' src={Hub}></img>
        </Col>
        <Col className='md-1'/>
      </Row>
      {!buy && <Row>
        <Col className='mt-4 window text-center'>
          <p className='engraved add-me'>I have a <br/> HUB</p>
        </Col>
        <Col className='mt-4 window text-center'>
          <p className='engraved add-me' onClick={()=>{setBuy(true);}}>I need a <br/> HUB</p>
        </Col>
      </Row>
      }
      <Row className={buy ? '' : 'hidden'}>
        <Col className={ 'window text-center in-use' }>
          <p>Buy Now!</p>
          {bt}
        </Col>
      </Row>
      <Row>
        <Col className='text-center'>
          <Button onClick={()=>{navigate('/dashboard')}} className='hub-orange-background w-100 mt-4' as="input" type="submit"  value="Back" size="lg"/>  
        </Col>
      </Row>
    </>

  return (
    <>
      <Header/>
      <Container  id = 'auth' className='bkgnd'>
        {mainDash}
      </Container>
    </>
  );
}


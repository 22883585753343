import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { useNavigate } from "react-router-dom";

function Home() {

  const navigate = useNavigate();

  return (
    <>
    <Container  id = 'auth'>
      <Row className=''>
        <Col className='text-center mt-5'>
          <h1 className='pt-2 hub-orange fw-bold home-logo'>OGO<br/>HUB</h1>
          <h1 className='pt-2 fw-medium engraved mt-4'>Coming Soon</h1>
        </Col>
      </Row>
    </Container>
    <Container>
      <Row className='bottom-login'>
        <Col className='text-center'>
          <p className='mt-5'>Early Adopter?</p>
          <Button className='hub-orange-background' onClick={()=>{navigate('/login')}}>Log In Here</Button>
          <p className='mt-5'><a className='fw-bold link' onClick={()=>{navigate('/privacy')}}>Privacy Policy</a> and <a className='fw-bold link' onClick={()=>{navigate('/terms')}}>Terms and Conditions</a></p>
        </Col>
      </Row>
    </Container>
    </>
  );
}

export default Home;

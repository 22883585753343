import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout, selectUserName } from "../features/auth/authSlice";
import Header from "./Header";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Calendar from "./Calendar"; 
import Square from "./Square";

export default function Dashboard() {
  const dispatch = useDispatch();
  const username = useSelector(selectUserName);
  const [calendarInUse, setCalendarInUse] = useState(false);

  const navigate = useNavigate();

  
  const mainDash =  
    <>
      <Container  id = 'auth' className=''>
        <Row>
          <Col className='mt-4 window flat text-center'>
            <p className='engraved add-me flat' onClick={()=>{navigate('/purchase')}}>Add HUB</p>
          </Col>
          <Col className='mt-4 window flat text-center'>
            <p className='engraved add-me flat'>Add Thermo</p>
          </Col>
        </Row>
        <Row>
          <Col className='window text-center'>
            <Square username={username}/> 
          </Col>
        </Row>
      </Container>
      <Calendar  calendarInUse={calendarInUse} callbackToActivate={setCalendarInUse} username={username}/>
      <Container  id = 'auth' className=''>
        <Row>
          <Col className='text-center'>
            <Button className='hub-orange-background w-100 mt-4 mb-4' as="input" type="submit" onClick={() => {dispatch(logout())}} value="Log Out" size="lg"/>  
          </Col>
        </Row>
      </Container>
    </>

  return (
    <>
      <Header/>
      {mainDash}
    </>
  );
}


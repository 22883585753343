import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";

import {useGetUserApisQuery, useCreateApiAuthMutation} from "../app/api";
import { useGetSquareSalesQuery } from '../app/api';
import Container from 'react-bootstrap/esm/Container';
/*import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/esm/Button';*/

export default function Square({username}) {
  /** get User Specific API settings **/
  const [accessToken, setAccessToken] = useState(null);

  const [runAgain, setRunAgain] = useState(0);
  const {data: apiData} = useGetUserApisQuery({username: username, runAgain: runAgain}); // runAgain triggering rerun on change

  const {data:squareSales} = useGetSquareSalesQuery({username: username, token:accessToken}, {skip: !accessToken});
  console.log('squareSales', squareSales);

  const [addApiValue] = useCreateApiAuthMutation();

  const squareClientID = 'sandbox-sq0idb-bhCb5MnNBosUaWckZG6Fpw';
  const squareUniqueID = createUniqueID(10);
  // PROD // const squareURL = 'https://connect.squareup.com/oauth2/authorize?client_id=' + squareClientID + '&scope=CUSTOMERS_WRITE+CUSTOMERS_READ&session=false&state=' + squareUniqueID;
  const squareURL = 'https://connect.squareupsandbox.com/oauth2/authorize?client_id=' + squareClientID + '&scope=ORDERS_READ+MERCHANT_PROFILE_READ&session=false&state=' + squareUniqueID;


  /* 
  first get location ID:

  curl https://connect.squareupsandbox.com/v2/locations \
  -H 'Square-Version: 2024-03-20' \
  -H 'Authorization: Bearer EAAAl7EYDceoTkbg8814EM2_5k_efsx2K5viG_FL0mqjveaoMWLnY6fjOxxTijc3' \
  -H 'Content-Type: application/json'

  Then query orders:

  curl https://connect.squareupsandbox.com/v2/orders/search \
  -X POST \
  -H 'Square-Version: 2024-03-20' \
  -H 'Authorization: Bearer EAAAl7EYDceoTkbg8814EM2_5k_efsx2K5viG_FL0mqjveaoMWLnY6fjOxxTijc3' \
  -H 'Content-Type: application/json' \
  -d '{
    "query": {
      "filter": {
        "date_time_filter": {
          "created_at": {
            "start_at": "2024-03-30T21:35:55.343Z"
          }
        }
      }
    },
    "location_ids": [
      "LFN6YBS4WDB78"
    ]
  }'
  */

  // check for access Token
  useEffect(() => {
    console.log('checking for accessToken');
    if (apiData) {
      let squareType = apiData.filter(t => t.api_type == 'SQUARE')?.filter(t => t.api_value != 'temp')?.sort((a,b) => a-b).reverse(); 
      if (squareType) {
        console.log('setting accessToken');
        setAccessToken(squareType[0]?.api_value);
      }
    }
  }, [apiData]);

  // check for square location ID
  useEffect(() => {
    if (apiData && accessToken) {
      let squareType = apiData.filter(t => t.api_type == 'SQUARE_LOC')?.filter(t => t.api_value != 'temp')?.sort((a,b) => a-b).reverse(); 
      console.log('checking for square loc.....', squareType);
    }
  }, [apiData]);


  const authenticateSquare = () => {
  
    addApiValue({username: username, api_type: 'SQUARE_AUTH', api_value: 'temp', unique_id: squareUniqueID})
    window.open(squareURL, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    setTimeout(function(){
        setRunAgain(runAgain + 1);
    }
      , 5000);
  }

  const runSquare = () =>  {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    let amount = 0;
    let finalAmount = 0;
    if (squareSales) {
      squareSales.map((t) => {
        amount = amount + t.total_money.amount;
      })
      finalAmount = amount == 0 ? 0 : amount/100;
    }
    return (
          <>
            <h1 className='window-header mt-2'>Sales Today</h1>
            <Container className='text-left p-2 text-center'>
              <h2>${finalAmount}</h2>
            </Container>
          </>
    )
  }

  /* now done on server side
  const getSquareLocation = () => {
    const locURL = 'https://connect.squareupsandbox.com/v2/locations';
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        //let eventInfo = JSON.parse(this.response);
        console.log('location result', this.response);
      }
      else {
        if (this.readyState == 4 && this.status != 200) {
          console.log('location token error:', this);
        }
      }
    };
    xhr.open('GET', locURL); 
    xhr.setRequestHeader('Square-Version','2024-03-20')
    xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
    xhr.setRequestHeader('Content-Type','application/json');
    xhr.send();
  }
  */

  return (
    <div id = 'calendar'>
      {accessToken ?
        runSquare()
      :
      <p onClick={()=>{authenticateSquare()}} className='engraved add-me'>Add<br/> POS</p>
      }
    </div>
  );
}

Square.propTypes = {
  username: PropTypes.string,
}


export const createUniqueID = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    let timestamp = Date.now();
    return result + '_' + timestamp;
}
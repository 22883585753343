import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  progress: 'start',
};

export const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    saveCalendar: (state, action) => {
      const payload = action.payload;
      switch (payload.type) {
        case 'progress':
          state.progress = state.progress + ', ' + payload.value;
          break;
      }
    },
  }
})

export const selectProgress = (state) => state.calendar.progress;

export const { saveCalendar } = calendarSlice.actions;
export default calendarSlice.reducer;
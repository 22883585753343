import React, {useState} from "react"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import dompurify from 'dompurify'; 

import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';

import OgoMenu from "./OgoMenu";

import PropTypes from "prop-types"; 

export default function OgoEvent({ogoEvent, show=true, handleClose, addOgoEvent, removeOgoEvent, description, menuCallback}) {

  const [showMenu, setShowMenu] = useState(false);

  const mapDom = (domString) => {
    return (<div dangerouslySetInnerHTML={{__html: dompurify.sanitize(domString.replace('\n\n\n','<br/>').replace('\n','<br/').replace('#ogoevent',''), {FORCE_BODY:true})}} />);
  }

  const decideCloseEvent = () => {
    if (showMenu) {
      setShowMenu(false);
    }
    else {
      handleClose();
    }
  }

  return (
    <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{ogoEvent.summary}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { showMenu ? 
          <OgoMenu ogoId={ogoEvent.id} callback={menuCallback}/>
          :
          <Container>
            <Row>
              <Col className='text-center mb-3'>
                {isOgoEvent(ogoEvent) && <div className='hub-orange mb-2'><b>OGO EVENT</b></div>}
              </Col>
            </Row>
            <Row>
              <Col>
                {isOgoEvent(ogoEvent) ? <Button className='mb-4 w-100'  onClick={()=>{removeOgoEvent(ogoEvent.id)}}>Remove Ogo</Button> : <Button className='mb-4 w-100' onClick={()=>{addOgoEvent(ogoEvent.id)}}>Add Ogo</Button> }
              </Col>
              <Col>
                {isOgoEvent(ogoEvent) && <Button className='mb-4 w-100'  onClick={()=>{setShowMenu(true)}}>Menu</Button> }
              </Col>
            </Row>
            <Row>
              <Col>
                {mapDom(description)}
              </Col>
            </Row>

          </Container>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={decideCloseEvent}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
  );
}

OgoEvent.propTypes = {
  ogoEvent: PropTypes.object,
  handleClose: PropTypes.func,
  show: PropTypes.bool,
  addOgoEvent: PropTypes.func,
  removeOgoEvent: PropTypes.func,
  description: PropTypes.string,
  menuCallback: PropTypes.func,
}

export const isOgoEvent = (ogoEvent) => {
  return ogoEvent.description?.toLowerCase().includes('#ogoevent') ? true : false;
}
